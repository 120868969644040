<template>
	<div class="page container">
		<v2-back-btn route="/about" />
		<div class="page__info">
			<div class="page__info--title">
				{{ getCategoryTitle }}
			</div>
			<div class="page__info--subtitle" v-if="getFeaturedHeading">
				{{ getFeaturedHeading }}
			</div>
		</div>
		<div class="content">
			<div class="main">
				<section>
					<div data-element="articles">
						<article-excerpt
							v-for="(article, key) in getFeaturedArticles"
							:key="key"
							:article="article"
							:categorySlug="category.slug"
						/>
					</div>
				</section>
				<section>
					<h1
						data-element="section-heading"
						v-if="getOthersHeading"
					>
						{{ getOthersHeading }}
					</h1>
					<div data-element="articles">
						<article-excerpt
							v-for="(article, key) in getOthersArticles"
							:key="key"
							:article="article"
							:categorySlug="category.slug"
						/>
					</div>
				</section>
			</div>
			<div class="side">
				<sidebar-list
					v-if="getShowCategoriesSidebar"
					heading="Other categories"
					:listItems="getCategoriesSidebarListItems"
					listItemIcon="info"
				/>
			</div>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>

	import ArticleExcerpt  from '@/components/knowledgebase/view/ArticleExcerpt';
	import SidebarList     from '@/components/knowledgebase/view/SidebarList';
	import routeParams     from '@/mixins/routeParams';
	import api             from '@/services/api';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'About'
		},
		components: {
			ArticleExcerpt,
			SidebarList,
			V2BackBtn
		},
		mixins: [routeParams],
		data: () => ({
			category: {},
			articles: [],
			allCategories: []
		}),
		computed: {
			getCategoryTitle () {
				return this.category?.title;
			},
			getFeaturedHeading () {
				if (!this.getCategoryTitle) {
					return false;
				}
				const cat = this.getCategoryTitle.toLowerCase();
				return `Featured ${cat} articles`;
			},
			getOthersHeading () {
				if (!this.getCategoryTitle) {
					return false;
				}
				if (!this.getOthersArticles.length) {
					return false;
				}
				const cat = this.getCategoryTitle.toLowerCase();
				return `Other ${cat} articles`;
			},
			getFeaturedArticles () {
				return this.articles.filter((article) => {
					return article.featured;
				});
			},
			getOthersArticles () {
				return this.articles.filter((article) => {
					return !article.featured;
				});
			},
			getShowCategoriesSidebar () {
				if (!this.getCategoriesSidebarListItems.length ||
						!this.getCategoriesSidebarHeading) {
					return false;
				}
				return true;
			},
			getCategoriesSidebarHeading () {
				return 'Other categories';
			},
			getCategoriesSidebarListItems () {
				if (!this.allCategories) {
					return [];
				}
				return this.allCategories.map((category) => {
					return {
						text: category.title,
						route: `/about/${category.slug}`
					};
				});
			},
			getBreadcrumbs () {
				return [
					{
						path: '/about',
						text: 'About'
					},
					{
						path: `/about/${this.getCategorySlug}`,
						text: this.getCategoryTitle
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setCategory();
		},
		methods: {
			async setCategory () {
				const response = await api.knowledgebase.getCategoryBySlug({
					slug: this.$route.params.categorySlug,
					include: [
						'metadata',
						'categories'
					]
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.category = response.meta.category;
				this.allCategories = response.meta.allCategories;
				this.articles = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>
.page__info {
  margin-bottom: 24px;
}
section {
  &:last-child {
    margin-bottom:0;
  }
  [data-element="section-heading"] {
    margin:0 0 rem(32);
    font-size:rem(30);
    font-weight:700;
    color:$c-brand-blue;
  }
  [data-element='articles'] {
    [data-component='article-excerpt'] {
      margin-bottom:rem(38);
    }
  }
}

</style>

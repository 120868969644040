<template>
	<article data-component="article-excerpt">
		<h1 data-element="title">
			<router-link
				data-element="article-link"
				:to="getArticleRoute"
			>
				{{ article.title }}
			</router-link>
		</h1>
		<div
			data-element="excerpt"
			v-if="article.excerpt"
			v-html="article.excerpt"
		/>
		<router-link
			data-element="link"
			:to="getArticleRoute"
		>
			<span>
				Read more
			</span>
			<icon
				icon="chevron-left"
				colour="darkest-navy"
			/>
		</router-link>
	</article>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			article: {
				type: Object,
				default: () => ({})
			},
			categorySlug: {
				type: String,
				default: () => ({})
			}
		},
		computed: {
			getArticleRoute () {
				const categoryRoute = `/about/${this.categorySlug}`;
				const articleSlug = this.article.slug;
				return `${categoryRoute}/${articleSlug}`;
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='article-excerpt'] {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid $c-gray-l2;
    background: $c-white-l;
    padding: 16px;

		[data-element='title'] {
			margin:0 0 rem(12);
			a {
        @include font(22px, $c-dark-navy, 700, 28px)
			}
		}
		::v-deep [data-element='excerpt'] {
			margin:0 0 rem(12);
			font-size:rem(16);
			@include rich-text-suits;
			@include rich-text-content;
		}
		[data-element='link'] {
			display:flex;
			align-items:center;
			span {
        @include font(16px, $c-darkest-navy, 700, 20px)
			}
			[data-component='icon'] {
				flex-shrink:0;
				width:rem(18);
				height:auto;
				transform:rotate(180deg);
			}
		}
	}
</style>
